.alice-carousel .animated {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.alice-carousel .animated-out {
  z-index: 1;
}

.alice-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}
